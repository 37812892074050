// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Chevron icons */
.gg-chevron-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 2));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-chevron-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/icons.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,+BAA+B;EAC/B,WAAW;EACX,YAAY;EACZ,6BAA6B;EAC7B,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,cAAc;EACd,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,wBAAwB;EACxB,uBAAuB;EACvB,wBAAwB;EACxB,SAAS;EACT,QAAQ;AACV","sourcesContent":["/* Chevron icons */\n.gg-chevron-down {\n  box-sizing: border-box;\n  position: relative;\n  display: block;\n  transform: scale(var(--ggs, 2));\n  width: 22px;\n  height: 22px;\n  border: 2px solid transparent;\n  border-radius: 100px;\n}\n.gg-chevron-down::after {\n  content: \"\";\n  display: block;\n  box-sizing: border-box;\n  position: absolute;\n  width: 10px;\n  height: 10px;\n  border-bottom: 2px solid;\n  border-right: 2px solid;\n  transform: rotate(45deg);\n  left: 4px;\n  top: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
