import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "welcome": "Welcome to Compte-Gouttes Workshop",
      "validate_decision": "Validate Decision",
      "Suivant": "Next",
      "Précédent" : "Previous",
      "Déroulement de l'atelier": "How does it work ?",
      "Commencer le jeu": "Start the simulation",
      "Commencer l'atelier": "Start the simulation",
      "Voici les voix dont disposent chaque acteur pour ce tour": "Here are voices summary",
      "Tour" : "Round",
      "Valider la décision" : "Validate decisions",
      "Tours": "Rounds",
      "Économie d'eau": "Water Saving",
      "Agriculture": "Agriculture",
      "Industrie": "Industry",
      "Public": "Public",
      "Consommateurs": "Consumers",
      "Courbe d'objectif": "Target Curve",
      "endOfLastRound": "It's the end of your last turn!",
      "congratulations": "Congratulations!",
      "achievement": "You achieved {{percentage}}% of your goal.",
      "waterSavingsRecap": "Here is the recap of the water savings you made over time:",
      "welcomeMessage": "Hello everyone, it's {{year}}, welcome to the {{congressNumber}}th Water Congress.",
      "objectivesStatus": "The 10-year objectives are {{objectives}}, this year droughts are expected to be {{droughtType}}, decisions should focus on {{measuresTerm}} measures.",
      "lowPrecipitation": "This year precipitation levels were particularly low, some rivers are almost dry, and groundwater levels are under great stress. We are facing a drought.",
      "shortTermNeeds": "You need to save {{needs}}",
      "waterDrop": "water drop",
      "nextTurn": " in the next turn.",
      "waterSavingsEvolution": "Water Savings Evolution",
      "otherIndicators": "Other Indicators",
      "discoverTerritory": "Discover what your territory looks like!",
      "downloadPDF": "Download the PDF",
      "gameSummary": "Game Summary",
      "accessSummary": "Access the game summary",
      "reviewPastEvents": "Let's review the key events of the past year",
      "biodiversity": "Biodiversity",
      "energySobriety": "Energy Sobriety",
      "economicHealth": "Economic Health",
      "popularity": "Popularity",
      "waterQuality": "Water Quality",
      "context": {
        "title": "Context",
        "householdsWithoutWater": "households were deprived of water",
        "awarenessCampaign": "people sensitized to water"
      },
      "events": {
        "olympics": {
          "title": "Olympic Games",
          "description": "The Olympic Games took place this year.",
          "heatwaves": "heatwaves observed"
        },
        "cop30": {
          "title": "COP 30 in Brazil",
          "description": "COP 30 in Brazil",
          "hottestYear": "hottest year recorded"
        },
        "localElections": {
          "title": "Municipal Elections",
          "description": "Municipal elections were held this year.",
          "droughtDeaths": "deaths estimated due to droughts"
        },
        "presidentialElections": {
          "title": "Presidential Elections",
          "description": "Presidential elections were held this year.",
          "heatwaves": "heatwaves observed"
        },
        "warming": {
          "title": "Exceeding 1.4°C",
          "description": "The 1.4°C exceeded globally.",
          "dryDays": "days without rain"
        },
        "unknown": {
          "title": "Unknown Event",
          "description": "No specific event for this year.",
          "dryDays": "days without rain"
        }
      },
      "actors-round-votes": "Here are the votes available to each actor for this round:",
      "popularity-crisis-1": "Water restrictions have sparked growing discontent among citizens. Dry gardens, limited washing: frustration is mounting over the constraints imposed to preserve this vital resource. Protests have erupted, with the population demanding that everyone contributes to the collective effort and that measures do not target them alone. Awareness and collaborative solutions will be essential to find a sustainable balance.",
      "popularity-crisis-2": "In response to these demands, the prefect decides to redistribute one vote from each of the three most powerful actors to the consumers.",
      "biodiversity-crisis-1": "Previous measures to counter the biodiversity crisis have had detrimental effects on fragile ecosystems. Activists are criticizing the leaders for their flagrant laxity in the face of species loss, habitat destruction, and environmental degradation. Citizens are demanding concrete actions and collective responsibility to preserve our precious natural heritage. Awareness and bold political decisions will be essential to protect our planet.",
      "biodiversity-crisis-2": "In response to these demands, the prefect decides to redistribute one vote from each of the three most powerful actors to the OFB.",
      "carbon-crisis-1": "A major energy crisis is currently affecting the region, which is experiencing an unprecedented drought. Water levels are at record lows, and several nuclear power plants have had to slow down or even cease operations due to the risk of not being able to ensure their cooling. Emergency measures have been taken to encourage a reduction in energy consumption. A coal-fired power plant will be restarted to meet the demand.",
      "carbon-crisis-2": "In response to this situation, the prefect decides to redistribute one vote from each of the three most powerful actors to the energy sector.",
      "economy-crisis-1": "The current economic crisis has hit the region hard following measures taken as part of the water plan, impacting tourism and some major local industries. This endangers jobs, incomes, and the region's attractiveness, with massive layoffs and business closures. A concerted approach and innovative solutions will be necessary to overcome these major economic challenges and restore regional vitality.",
      "economy-crisis-2": "In response to this situation, the prefect decides to redistribute one vote from each of the three most powerful actors to the industrial sector.",
      "quality-crisis-1": "A water quality crisis is affecting the region following revelations from independent media such as Brut and Mediapart about non-compliance with established standards. Concerns are growing among residents, jeopardizing public health. Decisive actions are needed to restore water quality and regain citizens' trust in their potable water supply.",
      "quality-crisis-2": "In response to this situation, the prefect decides to redistribute one vote from each of the three most powerful actors to the DREAL.",
      "game-summary-title": "End of Game Report",
      "game-summary-actors-highlighted": "The actors who stood out:",
      "game-summary-most-effort": "Actor who made the most effort:",
      "game-summary-team-results": "Your team's results:",
      "game-summary-decisions-taken": "decisions made during the game",
      "game-summary-better-than-other-games": "of other games are worse",
      "game-summary-return-home": "Return to Home",
    
    },
  },



  fr: {
    translation: {
      "welcome": "Bienvenue dans l'Atelier Compte-Gouttes",
      "validate_decision": "Valider la décision",
      "Suivant": "Suivant",
      "Précédent" : "Précédent",
      "Déroulement de l'atelier": "Déroulement de l'atelier",
      "Commencer le jeu": "Commencer le jeu",
      "Commencer l'atelier": "Commencer l'atelier",
      "Voici les voix dont disposent chaque acteur pour ce tour": "Voici les voix dont disposent chaque acteur pour ce tour",
      "Tour": "Tour",
      "Valider la décision" : "Valider la décision",
      "Tours": "Tours",
      "Économie d'eau": "Économie d'eau",
      "Agriculture": "Agriculture",
      "Industrie": "Industrie",
      "Public": "Public",
      "Consommateurs": "Consommateurs",
      "Courbe d'objectif": "Courbe d'objectif",    
      "endOfLastRound": "C'est la fin de votre dernier tour !",
      "congratulations": "Félicitations !",
      "achievement": "Vous avez atteint {{percentage}}% de votre objectif.",
      "waterSavingsRecap": "Voici le récapitulatif des économies d'eau que vous avez réalisées au cours du temps :",
      "welcomeMessage": "Bonjour à toutes et tous, nous sommes en {{year}}, bienvenue au {{congressNumber}}ème Congrès sur l'eau.",
      "objectivesStatus": "Les objectifs à horizon 10 ans sont en {{objectives}}, cette année on prévoit des sécheresses {{droughtType}}, il faudra axer nos décisions sur des mesures {{measuresTerm}}.",
      "lowPrecipitation": "Cette année les niveaux de précipitation ont été particulièrement faibles, certains cours d'eau sont bientôt à sec et les nappes phréatiques sont sous grande tension. Nous faisons face à une sécheresse.",
      "shortTermNeeds": "Vous devez économiser {{needs}}",
      "waterDrop": "goutte d'eau",
      "nextTurn": " lors du prochain tour.",
      "waterSavingsEvolution": "Évolution des économies d'eau",
      "otherIndicators": "Autres Indicateurs",
      "discoverTerritory": "Découvrez à quoi ressemble votre territoire !",
      "downloadPDF": "Télécharger le PDF",
      "gameSummary": "Bilan de partie",
      "accessSummary": "Accéder au bilan de partie",
      "reviewPastEvents": "Revenons sur les événements marquants de l'an passé",
      "biodiversity": "Biodiversité",
      "energySobriety": "Sobriété énergétique",
      "economicHealth": "Santé économique",
      "popularity": "Popularité",
      "waterQuality": "Qualité de l'eau",
      "context": {
        "title": "Contexte",
        "householdsWithoutWater": "personnes ont été privées d'eau",
        "awarenessCampaign": "personnes sensibilisées à l'eau"
      },
      "events": {
        "olympics": {
          "title": "Jeux Olympiques",
          "description": "Les Jeux Olympiques ont eu lieu cette année.",
          "heatwaves": "canicules observées"
        },
        "cop30": {
          "title": "COP 30 au Brésil",
          "description": "COP 30 au Brésil",
          "hottestYear": "année la plus chaude enregistrée"
        },
        "localElections": {
          "title": "Élections Municipales",
          "description": "Les élections municipales ont eu lieu cette année.",
          "droughtDeaths": "décès estimés à cause des sécheresses"
        },
        "presidentialElections": {
          "title": "Élections Présidentielles",
          "description": "Les élections présidentielles ont eu lieu cette année.",
          "heatwaves": "canicules observées"
        },
        "warming": {
          "title": "Dépassement des 1,4°C",
          "description": "Le seuil de 1,4° dépassé.",
          "dryDays": "jours sans pluie"
        },
        "unknown": {
          "title": "Événement inconnu",
          "description": "Aucun événement spécifique pour cette année.",
          "dryDays": "jours sans pluie"
        }
      },
      "actors-round-votes": "Voici les voix dont disposent chaque acteur pour ce tour :",
      "popularity-crisis-1": "Les restrictions d'eau ont suscité un mécontentement croissant parmi les citoyens. Jardins asséchés, lavages limités : la frustration grandit face aux contraintes imposées pour préserver cette ressource vitale. Des manifestations ont éclaté, la population demandant que tous participent à l'effort collectif et que les mesures ne les ciblent pas seuls. Sensibilisation et solutions collaboratives seront essentielles pour trouver un équilibre durable.",
      "popularity-crisis-2": "Face à ces revendications, le préfet décide de redistribuer une voix de chacun des trois acteurs les plus puissants aux consommateurs.",
      "biodiversity-crisis-1": "Les mesures précédentes pour contrer la crise de biodiversité ont eu des conséquences néfastes sur les écosystèmes fragiles. Des activistes reprochent un laxisme flagrant des dirigeants, face à la perte de diversité des espèces, la destruction des habitats et la dégradation de l'environnement. Les citoyens exigent des actions concrètes et une prise de responsabilité collective pour préserver notre précieux héritage naturel. Sensibilisation et décisions politiques audacieuses seront essentielles pour protéger notre planète.",
      "biodiversity-crisis-2": "Face à ces revendications, le préfet décide de redistribuer une voix de chacun des trois acteurs les plus puissants à l’OFB.",
      "carbon-crisis-1": "Une crise énergétique de grande ampleur frappe actuellement la région, qui fait face à une sécheresse sans précédent. Les cours d’eau sont au plus bas et plusieurs centrales nucléaires ont dû ralentir voire même stopper leur activité à cause du risque de ne pas pouvoir assurer leur refroidissement. Des mesures ont été prises en urgence pour inciter à la diminution de la consommation énergétique. Une centrale à charbon va être relancée pour pallier à la demande.",
      "carbon-crisis-2": "Face à cette situation, le préfet décide de redistribuer une voix de chacun des trois acteurs les plus puissants au secteur de l’énergie.",
      "economy-crisis-1": "La crise économique actuelle a durement frappé la région suite aux mesures prises dans le cadre du plan eau, impactant le tourisme et certaines grandes industries locales. Cela met en péril l'emploi, les revenus et l'attractivité de la région, avec des licenciements massifs et des fermetures d'entreprises. Une approche concertée et des solutions innovantes seront nécessaires pour surmonter ces défis économiques majeurs et restaurer la vitalité régionale.",
      "economy-crisis-2": "Face à cette situation, le préfet décide de redistribuer une voix de chacun des trois acteurs les plus puissants au secteur industriel.",
      "quality-crisis-1": "Une crise de qualité de l'eau frappe la région, suite aux révélations de médias indépendants tels que Brut ou Mediapart sur des non-conformités aux normes établies. Les inquiétudes grandissent parmi les résidents, mettant en jeu la santé publique. Des actions décisives sont nécessaires pour rétablir la qualité de l'eau et regagner la confiance des citoyens dans leur approvisionnement en eau potable.",
      "quality-crisis-2": "Face à cette situation, le préfet décide de redistribuer une voix de chacun des trois acteurs les plus puissants à la DREAL.",
      "game-summary-title": "Rapport de fin de partie",
      "game-summary-actors-highlighted": "Les acteurs qui se sont démarqués :",
      "game-summary-most-effort": "Acteur qui a fait le plus d'effort :",
      "game-summary-team-results": "Vos résultats d'équipe :",
      "game-summary-decisions-taken": "décisions prises au cours de la partie",
      "game-summary-better-than-other-games": "des autres parties sont moins bonnes",
      "game-summary-return-home": "Retour à l'accueil",
    }   
  },
};

i18n
  .use(initReactI18next) // Passes i18n instance to react-i18next.
  .init({
    resources,
    lng: "fr", // Default language
    fallbackLng: "fr", // Fallback language if the selected one is not available
    interpolation: {
      escapeValue: false, // React already escapes strings
    },
  });

export default i18n;
