import React from "react";
import { Radar } from "react-chartjs-2";

const RadarChart = ({ data_radar, t }) => {
  const data = {
    labels: [
      t("biodiversity"),
      t("energySobriety"),
      t("economicHealth"),
      t("popularity"),
      t("waterQuality"),
    ],
    datasets: [
      {
        data: [
          data_radar["biodiversity"],
          data_radar["carbon_economy"],
          data_radar["economy"],
          data_radar["popularity"],
          data_radar["water_quality"],
        ],
        backgroundColor: "rgba(0, 0, 0, 0)",
        borderColor: "rgba(75, 192, 192, 1)",
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(75, 192, 192, 1)",
      },
    ],
  };

  const options = {
    scale: {
      r: {
        min: 0,
        max: 10,
      },
      angleLines: {
        display: false,
      },
      ticks: {
        min: 0,
        max: 10,
        stepSize: 1,
      },
    },
    elements: {
      line: {
        borderWidth: 4,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: false,
    },
  };

  return <Radar data={data} options={options} />;
};

export default RadarChart;
