import React, { useState, useEffect } from "react";
import '../index.css';
import "../App.css"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from "react-router-dom";
import { faRankingStar, faDroplet } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from "react-i18next";

const GameSummary = ({ gameId }) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState('fr');

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  const [gameData, setGameData] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate()
  
  const fetchRoundData = async (gameId) => {
    return await (
      await fetch(
        `${process.env.REACT_APP_API_URL}/game/${gameId}/get_game_summary`
      )
    ).json();
  };

  const loadGameData = async (gameId) => {
    const newData = await fetchRoundData(gameId);
    setGameData(newData);
    setLanguage(newData.language);
  }

  useEffect(() => {
    // fetch data from API
    const initGraph = async () => {
      setLoading(true);
      await loadGameData(gameId);
      setLoading(false);
    };
    initGraph();
  }, [gameId]);

  if (loading) {
    // Affichage du chargeur pendant le chargement des données
    return (
      <div className="loader-container">
        <img src="/loader.gif" className="loader" alt="Chargement..." />
      </div>
    );
  }


  // Assurez-vous que gameData est une liste non vide
  if (!gameData || gameData.length === 0) {
    return <div>Aucune donnée de jeu disponible</div>;
  }


  const podium = gameData.best_players;
  const pireJoueur = gameData.worst_player;
  const stats = gameData.stat_game; // Modifiez ceci en fonction de la structure réelle des données
  const numberOfDecisions = gameData.number_of_decisions;

  const finishGame = async () => {
    navigate("/");
  };

  return (
    <div className="game-summary-container">
      <h1 className="game-summary-title">{t("game-summary-title")}</h1>
  
      <h2 className="game-summary-subtitle">{t("game-summary-actors-highlighted")}</h2>
  
      {/* Display the podium */}
      <div className="game-summary-grouped-container">
        <div className="game-summary-subcontainer-right">
          <FontAwesomeIcon icon={faRankingStar} className="game-summary-icon" />
  
          <ol className="game-summary-list">
            {podium.map((player, index) => (
              <li
                key={index}
                style={{
                  fontSize: "1em",
                  margin: "10px 0",
                  color: "var(--hydros-blue)",
                  textAlign: "center",
                }}
              >
                <span style={{ marginRight: "10px", fontSize: "1em" }}>
                  {index + 1} -
                </span>
                {player}
              </li>
            ))}
          </ol>
        </div>
        <div className="game-summary-subcontainer-left">
          <FontAwesomeIcon icon={faDroplet} className="game-summary-blue-icon" />
          <p className="game-summary-text">{t("game-summary-most-effort")}</p>{" "}
          <span className="game-summary-bold-text">{pireJoueur}</span>
        </div>
      </div>
  
      {/* Display the current player's stats */}
      <div>
        <h2 className="game-summary-subtitle">{t("game-summary-team-results")}</h2>
        <div className="game-summary-grouped-container">
          <div className="game-summary-subcontainer-right">
            <div className="game-summary-number">{numberOfDecisions}</div>
            <div className="game-summary-text">
              {t("game-summary-decisions-taken")}
            </div>
          </div>
          <div className="game-summary-subcontainer-bottom-left">
            <div className="game-summary-number">{stats}%</div>
            <div className="game-summary-text">
              {t("game-summary-better-than-other-games")}
            </div>
          </div>
        </div>
      </div>
      <div className="separator"></div>
      <button className="blue-button" onClick={finishGame}>
        {t("game-summary-return-home")}
      </button>
    </div>
  );  
};

export default GameSummary;
