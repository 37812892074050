import React, { useState } from "react";

const ActorCustomization = ({ formData, setFormData }) => {
  const [selectedActor, setSelectedActor] = useState("");
  const [customActorName, setCustomActorName] = useState("");

  const actorOptions = [
    { label: "Consommateurs", value: "consumers" },
    { label: "EDF", value: "edf" },
    { label: "Élus locaux", value: "elus-locaux" },
    { label: "Commerces locaux", value: "petit-commerce" },
    { label: "CCI", value: "industry" },
    { label: "Agriculteurs", value: "agriculture" },
    { label: "DREAL", value: "dreal" },
    { label: "OFB", value: "onb" },
    { label: "Industrie", value: "grosse-industrie" },
    { label: "Client", value: "client" },
    // Ajoute ici d'autres acteurs selon ton besoin
  ];

  const handleAddCustomActor = () => {
    if (selectedActor && customActorName) {
      const newActor = {
        actor_id: selectedActor,
        custom_actor_name: customActorName,
      };

      setFormData((prev) => ({
        ...prev,
        custom_actor_list: [...prev.custom_actor_list, newActor],
      }));

      // Réinitialiser les champs
      setSelectedActor("");
      setCustomActorName("");
    }
  };

  const handleRemoveCustomActor = (index) => {
    const updatedActors = formData.custom_actor_list.filter((_, i) => i !== index);
    setFormData({ ...formData, custom_actor_list: updatedActors });
  };

  return (
    <div className="form-group">
    <label>
      <input
        type="checkbox"
        checked={formData.custom_actor}
        onChange={() => {
          // Inverser la valeur de `custom_actor`
          const newCustomActorState = !formData.custom_actor;
          
          // Mettre à jour `formData` en fonction du nouvel état
          setFormData((prevData) => ({
            ...prevData,
            custom_actor: newCustomActorState,
            custom_actor_list: newCustomActorState ? prevData.custom_actor_list : [],
          }));
        }}
      />
      Activer la personnalisation des acteurs
    </label>

      {formData.custom_actor && (
        <div className="actor-customization">
          <select
            value={selectedActor}
            onChange={(e) => setSelectedActor(e.target.value)}
            className="actor-select"
          >
            <option value="">Sélectionner un acteur</option>
            {actorOptions.map((actor) => (
              <option key={actor.value} value={actor.value}>
                {actor.label}
              </option>
            ))}
          </select>

          <input
            type="text"
            placeholder="Nom personnalisé"
            value={customActorName}
            onChange={(e) => setCustomActorName(e.target.value)}
            className="input"
          />

          <button
            type="button"
            onClick={handleAddCustomActor}
            className="add-button"
          >
            Ajouter
          </button>
          <ul className="custom-actor-list">
        {formData.custom_actor_list.length > 0 ? (
          formData.custom_actor_list.map((actor, index) => (
            <li key={index} className="custom-actor-item">
              <span>{actor.custom_actor} ({actor.actor_id})</span>
              <button
                type="button"
                onClick={() => handleRemoveCustomActor(index)}
                className="remove-button"
              >
                Supprimer
              </button>
            </li>
          ))
        ) : (
          <p>Aucun acteur personnalisé ajouté.</p>
        )}
      </ul>
        </div>
        
      )}

      
    </div>
  );
};

export default ActorCustomization;
