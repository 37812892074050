// Context.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckToSlot, faMedal, faTemperatureThreeQuarters, faGlobe } from "@fortawesome/free-solid-svg-icons";

const Context = ({ status, t }) => {
  let title, description, iconEvent, householdsWithoutWater, context2, descriptionContext2, awarenessCampaign;

  switch (status.roundNumber) {
    case 0:
      title = t("events.olympics.title");
      description = t("events.olympics.description");
      iconEvent = faMedal;
      householdsWithoutWater = 35;
      context2 = 5;
      descriptionContext2 = t("events.olympics.heatwaves");
      awarenessCampaign = 2;
      break;
    case 1:
      title = t("events.cop30.title");
      description = t("events.cop30.description");
      iconEvent = faGlobe;
      householdsWithoutWater = 41;
      context2 = "14.7°C";
      descriptionContext2 = t("events.cop30.hottestYear");
      awarenessCampaign = 5;
      break;
    case 2:
      title = t("events.localElections.title");
      description = t("events.localElections.description");
      iconEvent = faCheckToSlot;
      householdsWithoutWater = 51;
      context2 = "6.5k";
      descriptionContext2 = t("events.localElections.droughtDeaths");
      awarenessCampaign = 20;
      break;
    case 3:
      title = t("events.presidentialElections.title");
      description = t("events.presidentialElections.description");
      iconEvent = faCheckToSlot;
      householdsWithoutWater = 43;
      context2 = 5;
      descriptionContext2 = t("events.presidentialElections.heatwaves");
      awarenessCampaign = 100;
      break;
    case 4:
      title = t("events.warming.title");
      description = t("events.warming.description");
      iconEvent = faTemperatureThreeQuarters;
      householdsWithoutWater = 37;
      context2 = 36;
      descriptionContext2 = t("events.warming.dryDays");
      awarenessCampaign = 500;
      break;
    default:
      title = t("events.unknown.title");
      description = t("events.unknown.description");
      iconEvent = faCheckToSlot;
      householdsWithoutWater = 35;
      context2 = 36;
      descriptionContext2 = t("events.unknown.dryDays");
      awarenessCampaign = 2;
  }

  return (
    <div>
      <div className="chart-title" style={{ textAlign: "center", marginTop: "-40px", marginLeft: "250px" }}>
        {t("context.title")}
      </div>
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <div style={{ display: "inline-block", margin: "0 15px" }}>
          <p style={{ fontSize: "3em", fontWeight: "bold", margin: "0", color: "var(--hydros-orange)" }}>
            {householdsWithoutWater}k
          </p>
          <p style={{ fontSize: "1em", margin: "0", color: "var(--hydros-blue)" }}>
            {t("context.householdsWithoutWater")}
          </p>
        </div>
        <div style={{ display: "inline-block", margin: "0 15px" }}>
          <p style={{ fontSize: "3em", fontWeight: "bold", margin: "0", color: "var(--hydros-orange)" }}>
            <FontAwesomeIcon icon={iconEvent} />
          </p>
          <p style={{ fontSize: "1em", margin: "0", color: "var(--hydros-blue)" }}>{title}</p>
        </div>
      </div>
      <div style={{ textAlign: "center", marginBottom: "50px" }}>
        <div style={{ display: "inline-block", margin: "0 15px" }}>
          <p style={{ fontSize: "3em", fontWeight: "bold", margin: "0", color: "var(--hydros-orange)" }}>{context2}</p>
          <p style={{ fontSize: "1em", margin: "0", color: "var(--hydros-blue)" }}>{descriptionContext2}</p>
        </div>
        <div style={{ display: "inline-block", margin: "0 15px" }}>
          <p style={{ fontSize: "3em", fontWeight: "bold", margin: "0", color: "var(--hydros-orange)" }}>
            {awarenessCampaign}k
          </p>
          <p style={{ fontSize: "1em", margin: "0", color: "var(--hydros-blue)" }}>
            {t("context.awarenessCampaign")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Context;
