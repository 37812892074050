import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../form.css';

const DecisionList = () => {
  const [decisions, setDecisions] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [tagColors, setTagColors] = useState({});
  const [availableLanguages, setAvailableLanguages] = useState([]);

  const navigate = useNavigate();

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Fetch les décisions via l'API
  useEffect(() => {
    const fetchDecisions = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/get_all_decisions`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setDecisions(data);

        // Générer les couleurs des tags et récupérer les langues
        const newTagColors = {};
        const allTags = [];
        const allLanguages = new Set();
        data.forEach((decision) => {
          decision.tags.forEach((tag) => {
            if (!newTagColors[tag]) {
              newTagColors[tag] = getRandomColor();
            }
            if (!allTags.includes(tag)) {
              allTags.push(tag);
            }
          });
          allLanguages.add(decision.language);
        });
        setTagColors(newTagColors);
        setAvailableTags(allTags);
        setAvailableLanguages([...allLanguages]);
      } catch (error) {
        console.error('Erreur lors du chargement des décisions :', error);
      }
    };

    fetchDecisions();
  }, []);

  // Fonction pour rediriger vers l'éditeur de décision
  const handleEdit = (decisionId) => {
    navigate(`/decision_editor/${decisionId}`);
  };

  // Fonction pour rediriger vers le créateur de décision
  const handleCreateNew = () => {
    navigate("/decision_creator");
  };

  // Gérer les changements des filtres
  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleLanguageChange = (e) => setSelectedLanguage(e.target.value);
  const handleTagToggle = (tag) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.includes(tag)
        ? prevSelectedTags.filter((t) => t !== tag)
        : [...prevSelectedTags, tag]
    );
  };

  // Filtrage des décisions
  const filteredDecisions = decisions.filter((decision) => {
    const matchesSearchText =
      decision.title.toLowerCase().includes(searchText.toLowerCase()) ||
      decision.description?.toLowerCase().includes(searchText.toLowerCase());
    const matchesLanguage =
      selectedLanguage === '' || decision.language === selectedLanguage;
    const matchesTags =
      selectedTags.length === 0 ||
      decision.tags.some((tag) => selectedTags.includes(tag));

    return matchesSearchText && matchesLanguage && matchesTags;
  });

  return (
    <div className="decision-list-container">
      <div className="decision-list-header">
        <button onClick={handleCreateNew} className="game-config-list-blue-button">
          Créer une nouvelle décision
        </button>
      </div>

      {/* Filtres */}
      <div className="consequence-filters">
        {/* Recherche Texte */}
        <input
          type="text"
          placeholder="Rechercher une décision..."
          value={searchText}
          onChange={handleSearchChange}
          className="consequence-filter-search"
        />

        {/* Filtre par Langue */}
        <select
          value={selectedLanguage}
          onChange={handleLanguageChange}
          className="consequence-filter-language"
        >
          <option value="">Toutes les langues</option>
          {availableLanguages.map((language) => (
            <option key={language} value={language}>
              {language}
            </option>
          ))}
        </select>
      </div>

      <div>
        {/* Filtre par Tags */}
        <div className="consequence-filter-tags">
          {availableTags.map((tag) => (
            <label key={tag} className="consequence-tag-filter">
              <input
                type="checkbox"
                checked={selectedTags.includes(tag)}
                onChange={() => handleTagToggle(tag)}
              />
              <span
                className="consequence-tag-label"
                style={{ backgroundColor: tagColors[tag] }}
              >
                {tag}
              </span>
            </label>
          ))}
        </div>
      </div>


      {/* Liste des Décisions */}
      <ul className="decision-list">
        {filteredDecisions.map((decision) => (
          <li key={decision._id} className="consequence-list-item">
            <div className="consequence-list-item-top">
              <div className="consequence-list-item-label">
                <div className={`consequence-language-label label-${decision.language}`}>
                  {decision.language}
                </div>
                <span className="consequence-list-name">{decision.title}</span>
              </div>
              <button
                onClick={() => handleEdit(decision._id)}
                className="decision-list-orange-button"
              >
                Éditer
              </button>
            </div>

            <div className="consequence-tags">
              {decision.tags.map((tag, index) => (
                <span
                  key={index}
                  className={`consequence-tag`}
                  style={{ backgroundColor: tagColors[tag] }}
                >
                  {tag}
                </span>
              ))}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DecisionList;
