import "./App.css";
import "./icons.css";
import Header from "./components/Header";
import Game from "./components/Game";
import GraphGame from "./components/GraphGame";
import ActorsBudgets from "./components/ActorsBudgets";
import MapPresentation from "./components/MapPresentation";
import GameTuto from "./components/GameTuto";
import GameConfigPage from "./components/GameConfigPage";
import Login from "./components/Login";
import Profile from "./components/Profile";
import useToken from "./components/useToken";
import GameSummary from "./components/GameSummary"
import FormReport from "./components/FormReport";
import AddMeasureForm from "./components/AddMeasureForm";
import ConsequenceForm from "./components/AddConsequenceForm";
import GameFormEditor from "./components/AddGameParameter";
import CustomIntroComponent from "./components/GameCustomIntro";
import GameConfigList from "./components/GameConfigList";
import DecisionList from "./components/DecisionList";
import ConsequenceListComponent from "./components/ConsequenceList";
import Unauthorized from "./components/ErrorPage/401";
import i18n from "./components/i18n";

import { Fragment, useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
  useParams,
  useLocation
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/game/:gameId",
    element: <GamePage />,
  },
  {
    path: "/game/:gameId/status",
    element: <GameStatus />,
  },
  {
    path: "/game/:gameId/summary",
    element: <GameSummaryPage />,
  },
  {
    path: "/game/:gameId/final_player_form",
    element: <FormReportPage />,
  },
  {
    path: "/intro/:gameId",
    element: <GameIntro />,
  },
  {
    path: "/custom_intro/:gameId",
    element: <CustomGameIntroPage />,
  },
  {
    path: "/tuto/:gameId",
    element: <GameTutorial />,
  },
  {
    path: "/config",
    element: <NewGameConfigPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/profile",
    element: <ProfileHomePage />,
  },
  {
    path: "/decision_list",
    element: <DecisionListPage />,
  },
  {
    path: "/decision_creator",
    element: <AddMeasurePage />,
  },
  {
    path: "/decision_editor/:decision_id",
    element: <EditMeasurePage />,
  },
  {
    path: "/consequence_list",
    element: <ConsequenceListPage />,
  },
  {
    path: "/consequence_creator",
    element: <AddConsequencePage />,
  },
  {
    path: "/consequence_editor/:consequence_id",
    element: <EditConsequencePage />,
  },
  {
    path: "/game_config_list",
    element: <GameConfigListPage />,
  },
  {
    path: "/game_config_creator",
    element: <GameConfigCreatorPage />,
  },
  {
    path: "/game_config_editor/:GameConfigId",
    element: <GameConfigEditorPage />,
  },
  {
    path: "/401",
    element: <Fragment><Header></Header><Unauthorized /></Fragment>
  },

  
]);

function GamePage() {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const [decisions, setDecisions] = useState([]);

  const [consequences, setConsequences] = useState([]);
  const [round, setRound] = useState(null);
  const [showConsequences, setShowConsequences] = useState(false);
  const [budget, setBudget] = useState(100);
  const [waterBudget, setWaterBudget] = useState(30);
  const [popularity, setPopularity] = useState(null);
  const [shortTermNeeds, setShortTermNeeds] = useState(0);
  const [initialShortTermNeeds, setInitialShortTermNeeds] = useState(0);
  const [actorsBudgets, setActorsBudgets] = useState([]);
  const [showActorsBudgets, setShowActorsBudgets] = useState(false);
  const [showAdditionalScore, setShowAdditionalScore] = useState(false);
  const [language, setLanguage] = useState('fr');

  const [selectedDecisions, setSelectedDecisions] = useState([]);
  const [canValidate, setCanValidate] = useState(false);

  const handleSelectDecision = (decision) => {
    const alreadySelectedDecision = selectedDecisions.find(
      (selectedDecision) => selectedDecision._id === decision._id
    );
    if (alreadySelectedDecision) {
      if (
        budget + decision.cost < 0 ||
        selectedDecisions.length <= 1 ||
        shortTermNeeds + decision.water_saving[0] > 0
      ) {
        setCanValidate(false);
      } else if (
        budget + decision.cost >= 0 &&
        selectedDecisions.length > 1 &&
        shortTermNeeds + decision.water_saving[0] <= 0
      ) {
        setCanValidate(true);
      }
      setSelectedDecisions([
        ...selectedDecisions.filter(
          (selectedDecision) => selectedDecision._id !== decision._id
        ),
      ]);
      setBudget(budget + decision.cost);
      setWaterBudget(waterBudget + decision.water_saving[4]);
      setShortTermNeeds(shortTermNeeds + decision.water_saving[0]);
    } else {
      if (
        budget - decision.cost >= 0 &&
        shortTermNeeds - decision.water_saving[0] <= 0
      ) {
        setCanValidate(true);
      } else if (
        budget - decision.cost < 0 ||
        shortTermNeeds - decision.water_saving[0] > 0
      ) {
        setCanValidate(false);
      }
      setBudget(budget - decision.cost);
      setWaterBudget(waterBudget - decision.water_saving[4]);
      setShortTermNeeds(shortTermNeeds - decision.water_saving[0]);
      setSelectedDecisions([...selectedDecisions, decision]);
    }
  };

  const hideConsequences = async () => {
    setLoading(true);
    setShowConsequences(false);
    setShowActorsBudgets(true);
    const decisions = await fetchDecisions(gameId);
    setDecisions(decisions);
    setLoading(false);
  };

  const [loading, setLoading] = useState(true);

  const validateDecisions = async () => {
    setLoading(true);
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        list_choosen_id: selectedDecisions.map(({ _id }) => _id),
      }),
    };
    await fetch(
      `${process.env.REACT_APP_API_URL}/game/${gameId}/post_choosen_decision`,
      options
    );

    navigate("/game/" + gameId + "/status");
  };

  const fetchDecisions = async (game_id) => {
    return await (
      await fetch(
        `${process.env.REACT_APP_API_URL}/game/${game_id}/get_decisions`
      )
    ).json();
  };

  const fetchConsequences = async (game_id) => {
    return await (
      await fetch(
        `${process.env.REACT_APP_API_URL}/game/${game_id}/get_consequences`
      )
    ).json();
  };

  const fetchGameStatus = async (game_id) => {
    return await (
      await fetch(
        `${process.env.REACT_APP_API_URL}/game/${game_id}/get_current_status`
      )
    ).json();
  };

  const updateGameData = async (id) => {
    const {
      water_budget,
      budget: newBudget,
      current_round_number,
      popularity,
      short_term_needs,
      budget_per_actor_list,
      show_additional_score,
      language
    } = await fetchGameStatus(id);
    setWaterBudget(water_budget);
    setBudget(newBudget);
    setRound(current_round_number);
    setInitialShortTermNeeds(short_term_needs);
    setShortTermNeeds(short_term_needs);
    setPopularity(popularity);
    setActorsBudgets(budget_per_actor_list);
    setShowAdditionalScore(show_additional_score);
    setLanguage(language);
  };

  useEffect(() => {
    // fetch data from API
    const initGame = async () => {
      setLoading(true);

      await updateGameData(gameId);
      const decisions = await fetchDecisions(gameId);
      setDecisions(decisions);
      //update budget, water budget, consequences, decisions
      const consequences = await fetchConsequences(gameId);
      if (consequences.length > 0) {
        setConsequences(consequences);
        setSelectedDecisions([]);
        setCanValidate(false);
        setShowConsequences(true);
      } else {
        setShowActorsBudgets(true);
      }
      setLoading(false);
    };
    initGame();
  }, [gameId]);

  const hideActorsBudgets = () => {
    setShowActorsBudgets(false);
  };

  return (
    <div className="App">
      {loading ? (
        <div className="loader-container">
          <img src="/loader.gif" className="loader" />
        </div>
      ) : (
        <Fragment>
          <Header
            budget={budget}
            waterBudget={waterBudget}
            round={round}
            shortTermNeeds={shortTermNeeds}
            initialShortTermNeeds={initialShortTermNeeds}
            popularity={popularity}
            language={language}
          />
          {showActorsBudgets && actorsBudgets.length > 0 ? (
            <ActorsBudgets
              actorsBudgets={actorsBudgets}
              handleNext={hideActorsBudgets}
              consequences={consequences}
              round={round}
              language={language}
            />
          ) : (
            <Game
              decisions={decisions}
              consequences={consequences}
              showConsequences={showConsequences}
              handleSelectDecision={handleSelectDecision}
              selectedDecisions={selectedDecisions}
              canValidate={canValidate}
              hideConsequences={hideConsequences}
              validateDecisions={validateDecisions}
              round={round}
              showAdditionalScore={showAdditionalScore}
              initialShortTermNeeds={initialShortTermNeeds}
              language={language}
            />
          )}
        </Fragment>
      )}
    </div>
  );
}

function HomePage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { token, removeToken, setToken } = useToken();

  const startGame = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (token) {
      navigate("/profile");
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <Fragment>
      {loading ? (
        <div className="loader-container">
          <img src="/loader.gif" className="loader" />
        </div>
      ) : (
        <Fragment>
          <Header />
          <div className="vertical-container">
            <h3 className="home-title">
              Bienvenue à l'Atelier Compte-Gouttes !
            </h3>
            <img
              src="/hydros_atelier.jpg"
              className="workshop-image"
              alt="hydros workshop"
            />
            <button className="blue-button" onClick={startGame}>
              Se connecter
            </button>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

function GameStatus() {
  const { gameId } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [decisionMetricsData, setDecisionMetricsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAdditionalScore, setShowAdditionalScore] = useState(false);
  const [language, setLanguage] = useState('fr');
  const [status, setStatus] = useState({
    roundNumber: 0,
    objectivesStatus: "danger",
    droughtType: "très intenses",
    measuresTerm: "court-termes",
  });

  const loadRoundData = async (gameId) => {
    return await (
      await fetch(
        `${process.env.REACT_APP_API_URL}/game/${gameId}/get_round_details`
      )
    ).json();
  };

  const fetchGameStatus = async (game_id) => {
    return await (
      await fetch(
        `${process.env.REACT_APP_API_URL}/game/${game_id}/get_current_status`
      )
    ).json();
  };

  const fetchGameDecisionMetrics = async (game_id) => {
    return await (
      await fetch(
        `${process.env.REACT_APP_API_URL}/game/${game_id}/get_round_metrics`
      )
    ).json();
  };

  const updateRoundData = async (gameId) => {
    const newData = await loadRoundData(gameId);
    const newDecisionMetricData = await fetchGameDecisionMetrics(gameId);
    const {
      water_budget,
      budget,
      current_round_number,
      short_term_needs,
      forecasting_of_water_budget,
      finish_graph,
      is_finished,
      popularity,
      show_additional_score,
      language
    } = await fetchGameStatus(gameId);
    const longTermSave =
      finish_graph[9].agriculture.total +
      finish_graph[9].consumer.total +
      finish_graph[9].industry.total +
      finish_graph[9].public.total;
    setStatus({
      isFinished: is_finished,
      roundNumber: current_round_number,
      waterBudget: water_budget,
      prevision: forecasting_of_water_budget,
      finishGraph: finish_graph,
      popularity,
      budget,
      objectivesStatus:
        Math.round((100 * Math.min(current_round_number, 5)) / 5) <=
        longTermSave
          ? "bonne voie"
          : "danger",
      droughtType:
        short_term_needs > 10
          ? "très intenses"
          : short_term_needs > 3
          ? "intenses"
          : "maîtrisées",
      measuresTerm: short_term_needs > 3 ? "court terme" : "long terme",
      shortTermNeeds: short_term_needs,
    });
    setData(newData);
    setDecisionMetricsData(newDecisionMetricData);
    setShowAdditionalScore(show_additional_score);
    setLanguage(language);
  };

  const goBackToGame = () => {
    navigate("/game/" + gameId);
  };

  useEffect(() => {
    // fetch data from API
    const initGraph = async () => {
      setLoading(true);
      await updateRoundData(gameId);
      setLoading(false);
    };
    initGraph();
  }, [gameId]);

  return (
    <Fragment>
      {loading ? (
        <div className="loader-container">
          <img src="/loader.gif" className="loader" />
        </div>
      ) : (
        <Fragment>
          <Header
            budget={status.budget}
            waterBudget={status.waterBudget}
            round={status.roundNumber}
            shortTermNeeds={status.shortTermNeeds}
            initialShortTermNeeds={status.shortTermNeeds}
            popularity={status.popularity}
            language={language}
          />
          <GraphGame
            data={data}
            decision_metrics_data={decisionMetricsData}
            goBackToGame={goBackToGame}
            status={status}
            showAdditionalScore={showAdditionalScore}
            language={language}
          ></GraphGame>
        </Fragment>
      )}
    </Fragment>
  );
}

function GameSummaryPage() {
  const { gameId } = useParams();



  return (
    <Fragment>
      <Header></Header>
      <GameSummary gameId={gameId}></GameSummary>
    </Fragment>
  )
}


function FormReportPage() {
  const { gameId } = useParams();
  return (
    <Fragment>
      <Header></Header>
      <FormReport gameId={gameId}></FormReport>
    </Fragment>
  )

}

function GameIntro() {
  const { gameId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bassinParam = queryParams.get('bassin');
  const language = queryParams.get('language');

  return (
    <Fragment>
      <MapPresentation gameId={gameId} bassin={bassinParam} language={language}></MapPresentation>
    </Fragment>
  );
}

function CustomGameIntroPage() {
  const { gameId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const configParam = queryParams.get('game_config');
  const language = queryParams.get('language');

  return (
    <Fragment>
      <Header></Header>
      <CustomIntroComponent gameId={gameId} configId={configParam} language={language}></CustomIntroComponent>
    </Fragment>
  );
}

function GameTutorial() {
  const { gameId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const language = queryParams.get('language');

  return (
    <Fragment>
      <GameTuto gameId={gameId} language={language}></GameTuto>
    </Fragment>
  );
}

function NewGameConfigPage() {
  const { token, removeToken, setToken } = useToken();

  return (
    <Fragment>
      <Header></Header>
      <GameConfigPage token={token}></GameConfigPage>
    </Fragment>
  );
}

function LoginPage() {
  const { token, removeToken, setToken } = useToken();

  return (
    <Fragment>
      <Header></Header>
      <Login setToken={setToken}></Login>
    </Fragment>
  );
}

function ProfileHomePage() {
  const { token, removeToken, setToken } = useToken();

  return (
    <Fragment>
      <Header></Header>
      <Profile token={token}></Profile>
    </Fragment>
  );
}

function DecisionListPage() {
return ( 
  <Fragment>
      <Header></Header>
      <DecisionList></DecisionList>
  </Fragment>
);
}

function AddMeasurePage() {
  return ( 
    <Fragment>
        <Header></Header>
        <AddMeasureForm edit={false} decision_id={undefined}></AddMeasureForm>
    </Fragment>
  );
}

function EditMeasurePage () {
  const {decision_id} = useParams();
  return ( 
    <Fragment>
        <Header></Header>
        <AddMeasureForm edit={true} decision_id={decision_id}></AddMeasureForm>
    </Fragment>
  );
}

function ConsequenceListPage () {
  return ( 
    <Fragment>
        <Header></Header>
        <ConsequenceListComponent></ConsequenceListComponent>
    </Fragment>
  );
}

function AddConsequencePage() {
  return ( 
    <Fragment>
        <Header></Header>
        <ConsequenceForm edit={false} consequence_id={undefined}></ConsequenceForm>
    </Fragment>
  );
}

function EditConsequencePage() {
  const {consequence_id} = useParams();

  return ( 
    <Fragment>
        <Header></Header>
        <ConsequenceForm edit={true} consequence_id={consequence_id}></ConsequenceForm>
    </Fragment>
  );
}

function GameConfigListPage() {
  return ( 
    <Fragment>
        <Header></Header>
        <GameConfigList></GameConfigList>
    </Fragment>
  );
}

function GameConfigCreatorPage() {
  return ( 
    <Fragment>
        <Header></Header>
        <GameFormEditor edit={false} GameConfigId={undefined}></GameFormEditor>
    </Fragment>
  );
}

function GameConfigEditorPage() {
  const {GameConfigId} = useParams();
  return ( 
    <Fragment>
        <Header></Header>
        <GameFormEditor edit={true} GameConfigId={GameConfigId}></GameFormEditor>
    </Fragment>
  );
}




function App() {
  return <RouterProvider router={router} />;
}

export default App;
