import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";



const Header = ({
  budget,
  waterBudget,
  round,
  popularity,
  shortTermNeeds,
  initialShortTermNeeds,
  language,
}) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);
  
return (
  <header className="App-header">
    <div className="logo-container" onClick={() => {navigate('/')}}>
      <img src="/hydros_full_logo.png" className="App-logo" alt="logo" />
      <div className="logo-text">L'Atelier Compte-Gouttes</div>
    </div>

    {round != null && round < 5 && (
      <div className="round">
        {t("Tour")} n°{round + 1} - {2025 + round}
        {initialShortTermNeeds > 0 && (
          <img src="/warning.png" className="warning-image" />
        )}
      </div>
    )}

    <div className="budgets color-sand">
      {budget != null && round < 5 && (
        <p className="budget">
          {budget}
          <img className="budget-currency" src="/vote-sand4.png" />
        </p>
      )}

      {waterBudget != null && (
        <div className="water-budget-container">
          <div className="gauge-container">
            <div
              className="gauge-content"
              style={{
                width:
                  String(100 - waterBudget > 100 ? 100 : 100 - waterBudget) +
                  "%",
              }}
            ></div>
          </div>
          {initialShortTermNeeds > 0 && (
            <p className="water-budget color-sand">
              {shortTermNeeds > 0 ? shortTermNeeds : 0}
              <img
                className="water-budget-drop"
                src="/hydros_logo.png"
                alt="water drop"
              />
            </p>
          )}
        </div>
      )}
      {/**
      {popularity != null &&
        (popularity <= 3 ? (
          <img src="/bad.png" className="popularity" alt="popularity" />
        ) : popularity >= 7 ? (
          <img src="/good.png" className="popularity" alt="popularity" />
        ) : (
          <img src="/medium.png" className="popularity" alt="popularity" />
        ))}
        */}
    </div>
  </header>
)};

export default Header;
