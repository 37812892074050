import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";


const GameTuto = ({ gameId, language}) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleNextClick = async () => {
    setLoading(true);
    navigate("/game/" + gameId);
    setLoading(false);
  };

  return loading ? (
    <div className="loader-container">
      <img src="/loader.gif" className="loader" />
    </div>
  ) : (
    <Fragment>
      <div class="text-intro-title">{t("Déroulement de l'atelier")}</div>
      <div class="tuto-video">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/e_ZhIm8_oCE?si=VnGFeecy8C3sxCDg"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
        ></iframe>
      </div>

      <div className="next-intro-section">
        <button class="blue-button" onClick={handleNextClick}>
          {t("Commencer le jeu")}
        </button>
      </div>
    </Fragment>
  );
};

export default GameTuto;
