import React, { Fragment, useEffect } from "react";
import Consequences from "./Consequences";
import Card from "./Card";

import { useTranslation } from "react-i18next";


const Game = ({
  decisions,
  handleSelectDecision,
  selectedDecisions,
  canValidate,
  validateDecisions,
  consequences,
  showConsequences,
  hideConsequences,
  round,
  initialShortTermNeeds,
  showAdditionalScore,
  language
}) => {
  const isSelected = (id) =>
    selectedDecisions.find((decision) => decision._id === id);
  
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  return (
    <div
      className={
        initialShortTermNeeds > 0 && !showConsequences
          ? "content-danger"
          : "content"
      }
    >
      {showConsequences ? (
        <Consequences
          consequences={consequences}
          hideConsequences={hideConsequences}
        />
      ) : (
        <Fragment>
          <div className="cards">
            {decisions.map((decision) => (
              <Card
                key={decision._id}
                decision={decision}
                selected={isSelected(decision._id)}
                handleSelect={handleSelectDecision}
                round={round}
                initialShortTermNeeds={initialShortTermNeeds}
                showAdditionalScore={showAdditionalScore}
                t={t}
              />
            ))}
          </div>
          {canValidate && (
            <div className="validate-section">
              <button className="blue-button" onClick={validateDecisions}>
                {t("Valider la décision")}
              </button>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Game;
