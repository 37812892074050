import {
  faChevronDown,
  faCircleChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


const ActorsBudgets = ({ actorsBudgets, handleNext, consequences, round, language}) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  const [happeningType, setHappeningType] = useState(null);

  const getColor = (name) => {
    switch (name) {
      case "onb":
        return "green-bg";
      case "dreal":
        return "blue-bg";
      case "edf":
        return "yellow-bg";
      case "consumers":
        return "red-bg";
      case "elus-locaux":
        return "white-bg";
      case "agriculture":
        return "orange-bg";
      case "petit-commerce":
        return "pink-bg";
      case "grosse-industrie":
        return "purple-bg";
      case "industry":
        return "purple-bg";
      case "agence-eau":
        return "gray-bg";
      default:
        return "default-bg";
    }
  };

  useEffect(() => {
    if (round === 3) {
      const happening = consequences.find(
        (consequence) => consequence.is_happening
      );
      if (happening) {
        setHappeningType(happening.happening_category);
      }
    }
  }, [JSON.stringify(consequences)]);
  return (
    <div className="content">
      <div className="vertical-container">
        <h3 className="recap-text no-bold">
          {t("actors-round-votes")}
        </h3>
        <div className="budgets-container">
          {actorsBudgets.map((actorBudget) => (
            <div className="budget-container" key={actorBudget.actor_id}>
              <p className="actor-name">{actorBudget.name}</p>
              <p className={"actor-budget " + getColor(actorBudget.actor_id)}>
                {actorBudget.budget}
                {actorBudget.actor_id === "elus-locaux" ||
                actorBudget.actor_id === "edf" ? (
                  <img className="currency" src="/vote-blue4.png" />
                ) : (
                  <img className="currency" src="/vote-white4.png" />
                )}
              </p>
            </div>
          ))}
        </div>
        {happeningType &&
          (happeningType === "popularity" ? (
            <div className="happening-text">
              {t("popularity-crisis-1")}
              <br />
              <br />
              {t("popularity-crisis-2")}
            </div>
          ) : happeningType === "biodiversity" ? (
            <div className="happening-text">
              {t("biodiversity-crisis-1")}
              <br />
              <br />
              {t("biodiversity-crisis-2")}
            </div>
          ) : happeningType === "carbon_economy" && actorsBudgets.length > 6 ? (
            <div className="happening-text">
              {t("carbon-crisis-1")}
              <br />
              <br />
              {t("carbon-crisis-2")}
            </div>
          ) : happeningType === "economy" ? (
            <div className="happening-text">
              {t("economy-crisis-1")}
              <br />
              <br />
              {t("economy-crisis-2")}
            </div>
          ) : happeningType === "water_quality" ? (
            <div className="happening-text">
              {t("quality-crisis-1")}
              <br />
              <br />
              {t("quality-crisis-2")}
            </div>
          ) : (
            <div></div>
          ))}
  
        <FontAwesomeIcon
          className="next"
          icon={faChevronDown}
          onClick={handleNext}
        />
      </div>
    </div>
  );  
};

export default ActorsBudgets;
