import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom"; 
import { v4 as uuidv4 } from "uuid";

import TagSelector from "./GameFormComponents/TagSelector";
import useToken from "./useToken";
import "../form.css";

const actorOptions = [
  { label: "Consommateurs", value: "consumers" },
  { label: "EDF", value: "edf" },
  { label: "Élus locaux", value: "elus-locaux" },
  { label: "Commerces locaux", value: "petit-commerce" },
  { label: "CCI", value: "industry"},
  { label: "Agriculteurs", value: "agriculture" },
  { label: "DREAL", value: "dreal" },
  { label: "OFB", value: "onb" },
  { label: "Industrie", value: "grosse-industrie"},
  { label: "Client", value: "client"},

  // Ajoute ici d'autres acteurs selon ton besoin
];

const handleKeyDown = (e) => {
  if (e.key === "Enter") {
    e.preventDefault(); // Empêche la soumission du formulaire
  }
};


const existingTags = ["standard", "atelier paris", "edf"];


const AddMeasureForm = ({edit, decision_id}) => {
  const [formData, setFormData] = useState({
    _id: uuidv4(),
    anecdote: "",
    concerned_actors: {},
    consequences_not_taken: [],
    consequences_taken: [],
    cost: 3,
    decision_metrics: {
      biodiversity: 0,
      carbon_economy: 0,
      economy: 0,
      popularity: 0,
      water_quality: 0,
    },
    description: "",
    duplicate: true,
    forced_round_number: -1,
    image_url: "",
    is_active: true,
    number: 0,
    owner: "",
    round_number: 0,
    sector: "consumer",
    short_description: "",
    source: [""],
    title: "",
    water_saving: [2, 2, 2, 3, 3],
    tags: [],
    language: 'fr'
  });

  const [actorImpact, setActorImpact] = useState([]);
  const [newActor, setNewActor] = useState({
    actorId: "",
    impact: "neutral",
    detail: "",
    money_modification: 0,
  });

  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null); // Image sélectionnée
  const [loadingImage, setLoadingImage] = useState(false); // État de chargement
  const [dragActive, setDragActive] = useState(false); // État de drag and drop

  const [showLongDescription, setShowLongDescription] = useState(true); // état pour contrôler le dépliage du champ description
  
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  // security check
  const { token } = useToken();

  const fetchProfileInfos = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/profile`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 422) {
          navigate("/login"); // Redirection si non autorisé
        } else {
          console.error("Erreur lors du chargement des données de profil :", response.statusText);
        }
        return;
      }
      const profileData = await response.json();
      // Vérification des droits d'accès
      if (!profileData.is_admin) {
        navigate("/401"); // Redirection si l'utilisateur n'est pas admin
      }
    } catch (error) {
      console.error("Erreur lors du chargement des données de profil :", error);
    }
  };

  useEffect(() => {
    fetchProfileInfos();
  }, []); 

  // Charger les de la mesure si on est en mode edit
  useEffect(() => {
    const fetchMesure = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/get_decision_by_id/${decision_id}`);
        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des conséquences");
        }
        const data = await response.json();
        setFormData(data); // Charger les conséquences dans l'état
        
        const actorList = Object.keys(data.concerned_actors).map(actor_id => ({
          actorId: actor_id,
          ...data.concerned_actors[actor_id]
        }));
        setActorImpact(actorList);
        setSelectedImage(data.image_url);

      } catch (error) {
        console.error("Erreur lors du chargement des conséquences:", error);
      } finally {
        setLoadingConsequences(false); // Arrêter le chargement
      }
      setLoading(false);
    };
    if (edit) {
      fetchMesure();
    }
   
  }, []);

  //consequence handler

  const [consequences, setConsequences] = useState([]); // Stocke toutes les conséquences de l'API
  const [loadingConsequences, setLoadingConsequences] = useState(true); // Pour afficher le chargement des conséquences
  const [isConsequencesOpen, setIsConsequencesOpen] = useState(false); // Contrôle le déploiement du menu

  // Charger les conséquences à partir de l'API lors du montage
  useEffect(() => {
    const fetchConsequences = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/get_all_consequences`);
        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des conséquences");
        }
        const data = await response.json();
        setConsequences(data); // Charger les conséquences dans l'état
      } catch (error) {
        console.error("Erreur lors du chargement des conséquences:", error);
      } finally {
        setLoadingConsequences(false); // Arrêter le chargement
      }
    };
    fetchConsequences();
  }, []);

// Fonction pour ajouter une conséquence dans consequences_taken
const handleAddConsequenceTaken = (consequenceId) => {
  setFormData((prevData) => ({
    ...prevData,
    consequences_taken: [...prevData.consequences_taken, consequenceId], // Ajoute l'ID de la conséquence si la mesure est prise
  }));
};

// Fonction pour ajouter une conséquence dans consequences_not_taken
const handleAddConsequenceNotTaken = (consequenceId) => {
  setFormData((prevData) => ({
    ...prevData,
    consequences_not_taken: [...prevData.consequences_not_taken, consequenceId], // Ajoute l'ID de la conséquence si la mesure n'est pas prise
  }));
};

// Fonction pour supprimer une conséquence de consequences_taken
const handleRemoveConsequenceTaken = (consequenceId) => {
  setFormData((prevData) => ({
    ...prevData,
    consequences_taken: prevData.consequences_taken.filter(
      (id) => id !== consequenceId // Retire l'ID de la conséquence si la mesure est prise
    ),
  }));
};

// Fonction pour supprimer une conséquence de consequences_not_taken
const handleRemoveConsequenceNotTaken = (consequenceId) => {
  setFormData((prevData) => ({
    ...prevData,
    consequences_not_taken: prevData.consequences_not_taken.filter(
      (id) => id !== consequenceId // Retire l'ID de la conséquence si la mesure n'est pas prise
    ),
  }));
};


  //image upload


  // Fonction pour télécharger l'image et obtenir l'URL de stockage
  const uploadImage = async (image) => {
    setLoadingImage(true);
    const formDataWithImage = new FormData();
    formDataWithImage.append("image", image);
    try {

      const response = await fetch(`${process.env.REACT_APP_API_URL}/upload_image`, {
        method: "POST",
        body: formDataWithImage,
      });
  
      // Vérifier si la requête a réussi
      if (!response.ok) {
        throw new Error("Erreur lors du téléchargement de l'image.");
      }
  
      // Récupérer l'URL de l'image depuis la réponse de l'API
      const data = await response.json();
  
      // Mettre à jour l'URL de l'image dans le formData
      setFormData((prevData) => ({
        ...prevData,
        image_url: data.image_url, // Assurez-vous que l'API renvoie un objet avec une propriété `url`
      }));
    } catch (error) {
      console.error("Erreur lors du téléchargement de l'image", error);
    } finally {
      setLoadingImage(false);
    }
  };

  // Gestion du changement d'image via input
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      uploadImage(file); // Télécharger l'image dès qu'elle est sélectionnée
    }
  };

  // Gestion du drag and drop
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedImage(file);
      uploadImage(file); // Télécharger l'image déposée
    }
  };

  // Suppression de l'image sélectionnée
  const handleRemoveImage = () => {
    setSelectedImage(null);
    setFormData((prevData) => ({
      ...prevData,
      image_url: "",
    }));
  };

  //actor


  const handleActorChange = (index, field, value) => {
    const updatedActors = [...actorImpact];
    updatedActors[index][field] = field === "money_modification" ? parseInt(value, 0) || 0 : value;
    setActorImpact(updatedActors);
  };

  const addActorImpact = () => {
    setActorImpact([...actorImpact, newActor]);
    setNewActor({ actorId: "", impact: "neutral", detail: "", money_modification: 0 });
  };

  const handleRemoveActorImpact = (index) => {
    const updatedActors = actorImpact.filter((_, i) => i !== index);
    setActorImpact(updatedActors);
  };

  // long short description

  // Mise à jour de la short_description et description si la longue description n'est pas dépliée
  const handleShortDescriptionChange = (e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      short_description: value,
      description: showLongDescription ? formData.description : value, // si le champ description n'est pas déplié, on garde la short_description comme description
    });
  };

  // Mise à jour de la description (longue) si dépliée
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, description: value });
  };

  // Handle changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;    
    setFormData({ ...formData, [name]: value });
  };

  // Handle changes
  const handleInputChangeInt = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: parseInt(value) });
  };

  // Handle changes for decision metrics
  const handleDecisionMetricsChange = (metric, value) => {
    const updatedMetrics = {
      ...formData.decision_metrics,
      [metric]: value,
    };
    setFormData({ ...formData, decision_metrics: updatedMetrics });
  };

  // Handle source changes
  const handleSourceChange = (index, value) => {
    const updatedSources = [...formData.source];
    updatedSources[index] = value;
    setFormData({ ...formData, source: updatedSources });
  };

  const addSource = () => {
    setFormData({ ...formData, source: [...formData.source, ""] });
  };

  const removeSource = (index) => {
    const updatedSources = formData.source.filter((_, i) => i !== index);
    setFormData({ ...formData, source: updatedSources });
  };

  const handleWaterSavingChange = (index, value) => {
    const updatedWaterSaving = [...formData.water_saving];
    updatedWaterSaving[index] = value;
    setFormData({ ...formData, water_saving: updatedWaterSaving });
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    // Bloquer le bouton Submit pendant la requête
    setSubmitDisabled(true);
    
    const updatedFormData = {
      ...formData,
      concerned_actors: actorImpact.reduce((acc, actor) => {
        acc[actor.actorId] = {
          detail: actor.detail,
          impact: actor.impact,
          money_modification: actor.money_modification,
        };
        return acc;
      }, {}),
    };

    const url = edit
      ? `${process.env.REACT_APP_API_URL}/replace_decision/${decision_id}`
      : `${process.env.REACT_APP_API_URL}/add_decision`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedFormData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // En cas de succès, afficher le message et désactiver le bouton submit
          setMessage("La mesure a été ajoutée avec succès.");
        } else {
          // En cas d'échec, afficher l'erreur et réactiver le bouton submit
          setMessage(`${data.message}`);
          setSubmitDisabled(false); // Réactiver le bouton en cas d'échec
        }
      })
      .catch((error) => {
        setMessage(`Erreur: ${error.message}`);
        setSubmitDisabled(false); // Réactiver le bouton en cas d'erreur de requête
      });
  };

  const handleBackToDecisions = () => {
    navigate("/decision_list"); // Rediriger vers la liste des décisions
  };

  return (
    <Fragment>
    {loading ? (
      <div className="loader-container">
        <img src="/loader.gif" className="loader" />
      </div>
    ) : (
    <div className="measure-form-container">
      <h1 className="form-title">Ajouter une nouvelle mesure</h1>
      <form onSubmit={handleSubmit} className="measure-form">
        <div className="form-section">
          <div className="form-section-title">Informations générales</div>

          {!edit ? (<div className="form-group">
            <label>ID :</label>
            <input
            type="text"
            name="_id"
            value={formData._id}
            onChange={(e) => setFormData({ ...formData, _d: e.target.value })}
            className="input"
            placeholder="Identifiant unique"
            onKeyDown={handleKeyDown}
            required
            />
          </div>):(<div><div>ID : {formData._id}</div><br></br></div>)}
          
          <div className="form-group">
            <label className="input-label">Titre :</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              className="input"
              placeholder="Titre de la mesure"
              onKeyDown={handleKeyDown}
              required
            />
          </div>

          {/* Zone de dépôt d'image avec drag and drop */}
          <div
            className={`dropzone ${dragActive ? "active" : ""}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <label className="input-label">Téléchargez une image :</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="input-file"
              style={{ display: "none" }} // Masquer l'input file
              id="image-upload"
            />
            <label htmlFor="image-upload" className="dropzone-label">
              {selectedImage
                ? `Image sélectionnée : `
                : "Glissez et déposez une image ou cliquez pour en sélectionner une"}
            </label>
            {selectedImage ?
            (<div>
              <a href={formData.image_url} target="_blank">Lien de l'image</a>
            </div>):(<></>)}
            {/* Indicateur de chargement */}
            {loadingImage && <div className="loading-indicator">Téléchargement en cours...</div>}

            {/* Afficher le bouton pour supprimer l'image si elle est sélectionnée */}
            {selectedImage && !loadingImage && (
              <button type="button" onClick={handleRemoveImage} className="remove-button">
                Supprimer l'image
              </button>
            )}
          </div>

          {/* Champ pour la short_description */}
          <div className="form-group">
            <label className="input-label">Description courte :</label>
            <textarea
              name="short_description"
              value={formData.short_description}
              onChange={handleShortDescriptionChange}
              className="input"
              placeholder="Description courte"
              required
            />
          </div>

          {/* Bouton pour déplier le champ description longue */}
          <div className="form-group">
            <button
              type="button"
              className="blue-button"
              onClick={() => {setShowLongDescription(!showLongDescription);
                                setFormData({
                                  ...formData,
                                  description: formData.short_description, 
                                });
                              }}
            >
              {showLongDescription ? "Supprimer la description longue" : "Ajouter une description longue"}
            </button>
          </div>

          {/* Champ pour la description longue qui est déplié seulement si l'utilisateur clique */}
          {showLongDescription && (
            <div className="form-group">
              <label className="input-label">Description longue :</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleDescriptionChange}
                className="input"
                placeholder="Description longue"
              />
            </div>
          )}

          <div className="form-group">
            <label className="input-label">Anecdote :</label>
            <textarea
              name="anecdote"
              value={formData.anecdote}
              onChange={(e) => setFormData({ ...formData, anecdote: e.target.value })}
              className="input"
              placeholder="Anecdote"
            />
          </div>
        </div>



        <div className="form-section">
          <div className="form-section-title">Conséquences liées à la mesure</div>
          {/* Bouton pour afficher les conséquences */}
          <div className="form-group">
            <button
              type="button"
              className="add-button"
              onClick={() => setIsConsequencesOpen(!isConsequencesOpen)}
            >
              Ajouter une conséquence
            </button>

            {/* Afficher la liste des conséquences si le menu est déplié */}
            {isConsequencesOpen && (
              <div className="consequences-list">
                {loadingConsequences ? (
                  <p>Chargement des conséquences...</p>
                ) : (
                  consequences.map((consequence) => (
                    <div key={consequence._id} className="consequence-item">
                      <div className="consequence-title">{consequence.title}</div>
                      <div className="button-consequence-section">
                        <button
                          type="button"
                          onClick={() => handleAddConsequenceTaken(consequence._id)}
                          className="select-consequence-button"
                        >
                          Ajouter si mesure prise
                        </button>
                        <button
                          type="button"
                          onClick={() => handleAddConsequenceNotTaken(consequence._id)}
                          className="select-consequence-button"
                        >
                          Ajouter si mesure non prise
                        </button>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>

          {/* Afficher les conséquences sélectionnées si la mesure est prise */}
          <div className="form-group">
            <label className="input-label">Conséquences si mesure prise :</label>
            <ul className="selected-consequences-list">
              {formData.consequences_taken.length > 0 ? (
                formData.consequences_taken.map((consequenceId, index) => (
                  <li key={index} className="selected-consequence-item">
                    {consequenceId}
                    <button
                      type="button"
                      onClick={() => handleRemoveConsequenceTaken(consequenceId)}
                      className="remove-consequence-button"
                    >
                      Supprimer
                    </button>
                  </li>
                ))
              ) : (
                <p>Aucune conséquence ajoutée pour une mesure prise.</p>
              )}
            </ul>
          </div>

          {/* Afficher les conséquences sélectionnées si la mesure n'est pas prise */}
          <div className="form-group">
            <label className="input-label">Conséquences si mesure non prise :</label>
            <ul className="selected-consequences-list">
              {formData.consequences_not_taken.length > 0 ? (
                formData.consequences_not_taken.map((consequenceId, index) => (
                  <li key={index} className="selected-consequence-item">
                    {consequenceId}
                    <button
                      type="button"
                      onClick={() => handleRemoveConsequenceNotTaken(consequenceId)}
                      className="remove-consequence-button"
                    >
                      Supprimer
                    </button>
                  </li>
                ))
              ) : (
                <p>Aucune conséquence ajoutée pour une mesure non prise.</p>
              )}
            </ul>
          </div>
        </div>


        
        <div className="form-section">
          <div className="form-section-title">Autres métriques de la mesure</div>
          {/* Section Decision Metrics */}
          <div className="form-group">
            <div className="metric-input-container">
              <label>Biodiversité :</label>
              <input
                type="number"
                min={-3}
                max={3}
                value={formData.decision_metrics.biodiversity}
                onChange={(e) => handleDecisionMetricsChange("biodiversity", Number(e.target.value))}
                className="input"
                onKeyDown={handleKeyDown}
              />
            </div>

            <div className="metric-input-container">
              <label>Économie :</label>
              <input
                type="number"
                min={-3}
                max={3}
                value={formData.decision_metrics.economy}
                onChange={(e) => handleDecisionMetricsChange("economy", Number(e.target.value))}
                className="input"
                onKeyDown={handleKeyDown}
              />
            </div>

            <div className="metric-input-container">
              <label>Économie de carbone :</label>
              <input
                type="number"
                min={-3}
                max={3}
                value={formData.decision_metrics.carbon_economy}
                onChange={(e) => handleDecisionMetricsChange("carbon_economy", Number(e.target.value))}
                className="input"
                onKeyDown={handleKeyDown}
              />
            </div>

            <div className="metric-input-container">
              <label>Qualité de l'eau :</label>
              <input
                type="number"
                min={-3}
                max={3}
                value={formData.decision_metrics.water_quality}
                onChange={(e) => handleDecisionMetricsChange("water_quality", Number(e.target.value))}
                className="input"
                onKeyDown={handleKeyDown}
              />
            </div>

            <div className="metric-input-container">
              <label>Popularité :</label>
              <input
                type="number"
                min={-3}
                max={3}
                value={formData.decision_metrics.popularity}
                onChange={(e) => handleDecisionMetricsChange("popularity", Number(e.target.value))}
                className="input"
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        </div>



        <div className="form-section">
          <div className="form-section-title">Coût et économie d'eau de la mesure</div>
          {/* Section Cost */}
          <div className="form-group">
            <label>Cost :</label>
            <input
              type="number"
              name="cost"
              min="1"
              max="20"
              value={formData.cost}
              onChange={handleInputChangeInt}
              className="input"
              onKeyDown={handleKeyDown}
            />
          </div>

          {/* Section Water Saving */}
          <div className="form-group water-saving-section">
            {formData.water_saving.map((value, index) => (
              <div key={index} className="water-saving-container">
                <label className="input-label">Année {index + 1}</label>
                <div className="slider-container">
                  <input
                    type="range"
                    min="0"
                    max="20"
                    value={value}
                    onChange={(e) => handleWaterSavingChange(index, parseInt(e.target.value))}
                    className="water-saving-slider"
                  />
                  <span className="water-saving-value">{value} unité(s)</span>
                </div>
              </div>
            ))}
          </div>
        </div>


        <div className="form-section">
          <div className="form-section-title">Impact sur les acteurs</div>
          {/* Section Concerned Actors */}
          <div className="form-group concerned-actors-section">
            {actorImpact.map((actor, index) => (
              <div key={index} className="actor-impact-card">
                {/* Sélection de l'acteur */}
                <div className="actor-input-container">
                  <label className="input-label">Acteur :</label>
                  <select
                    value={actor.actorId}
                    onChange={(e) => handleActorChange(index, "actorId", e.target.value)}
                    className="actor-select"
                  >
                    <option value="">Choisir un acteur</option>
                    {actorOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Sélection de l'impact */}
                <div className="impact-input-container">
                  <label className="input-label">Impact :</label>
                  <div className="radio-group">
                    <label className="radio-label">
                      <input
                        type="radio"
                        name={`impact-${index}`}
                        value="positive"
                        checked={actor.impact === "positive"}
                        onChange={(e) => handleActorChange(index, "impact", e.target.value)}
                      />
                      Positive
                    </label>
                    <label className="radio-label">
                      <input
                        type="radio"
                        name={`impact-${index}`}
                        value="negative"
                        checked={actor.impact === "negative"}
                        onChange={(e) => handleActorChange(index, "impact", e.target.value)}
                      />
                      Negative
                    </label>
                    <label className="radio-label">
                      <input
                        type="radio"
                        name={`impact-${index}`}
                        value="neutral"
                        checked={actor.impact === "neutral"}
                        onChange={(e) => handleActorChange(index, "impact", e.target.value)}
                      />
                      Neutral
                    </label>
                  </div>
                </div>

                {/* Détails */}
                <input
                  type="text"
                  placeholder="Détails de l'impact"
                  value={actor.detail}
                  onChange={(e) => handleActorChange(index, "detail", e.target.value)}
                  className="input detail-input"
                />

                {/* Impact financier */}
                <div className="financial-impact-container">
                  <label className="input-label">Impact sur les voix :</label>
                  <select
                    value={actor.money_modification}
                    onChange={(e) => handleActorChange(index, "money_modification", e.target.value)}
                    className="financial-select"
                  >
                    <option value={-1}>-1</option>
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </select>
                </div>

                {/* Bouton pour supprimer un acteur */}
                <button
                  type="button"
                  onClick={() => handleRemoveActorImpact(index)}
                  className="remove-actor-button"
                >
                  Supprimer l'acteur
                </button>
              </div>
            ))}

            {/* Bouton pour ajouter un autre impact */}
            <button type="button" onClick={addActorImpact} className="add-button">
              Ajouter un acteur
            </button>
          </div>
        </div>


        <div className="form-section">
          <div className="form-section-title">Source sur la mesure</div>
          {/* Section Sources */}
          <div className="form-group">
            {formData.source.map((src, index) => (
              <div key={index} className="source-input-container">
                <input
                  type="text"
                  value={src}
                  onChange={(e) => handleSourceChange(index, e.target.value)}
                  className="source-input"
                  placeholder="Ajouter une source"
                  onKeyDown={handleKeyDown}
                />
                <button type="button" onClick={() => removeSource(index)} className="remove-button">
                  Supprimer
                </button>
              </div>
            ))}
            <button type="button" onClick={addSource} className="add-button">
              Ajouter une source
            </button>
          </div>
        </div>

        <div className="form-section">
          <div className="form-section-title">Métadata</div>
          {/* Section Sector */}
          <div className="form-group">
            <label>Sector :</label>
            <select
              name="sector"
              value={formData.sector}
              onChange={handleInputChange}
              className="input"
            >
              <option value="consumer">Consumer</option>
              <option value="industry">Industry</option>
              <option value="public">Public</option>
              <option value="agriculture">Agriculture</option>
            </select>
          </div>
          {/* Section Language */}

          <div className="form-group">
            <label>Langage :</label>
            <select
              name="language"
              value={formData.language}
              onChange={handleInputChange}
              className="input"
            >
              <option value="fr">fr</option>
              <option value="en">en</option>
            </select>
          </div>

          

          {/* Section is_active */}
          <div className="form-group">
            <div className="radio-group">
            <label>Is Active ? </label>
              <input
                type="radio"
                id="activeTrue"
                name="is_active"
                value={true}
                checked={formData.is_active === true}
                onChange={() => setFormData({ ...formData, is_active: true })}
                className="radio-input"
                onKeyDown={handleKeyDown}
              />
              <label htmlFor="activeTrue" className="radio-label">True</label>

              <input
                type="radio"
                id="activeFalse"
                name="is_active"
                value={false}
                checked={formData.is_active === false}
                onChange={() => setFormData({ ...formData, is_active: false })}
                className="radio-input"
              />
              <label htmlFor="activeFalse" className="radio-label">False</label>
            </div>
          </div>
          
          <TagSelector formData={formData} setFormData={setFormData} existingTags={existingTags} />


        </div>

        {/* Afficher le message d'erreur ou de succès */}
        {message && <div className="message-box">{message}</div>}
        {/* Bouton "Retour aux décisions" affiché après la soumission */}
        {message && (
          <button type="button" onClick={handleBackToDecisions} className="submit-button">
            Retour aux décisions
          </button>
        )}

        {/* Le bouton submit */}
        <button type="submit" className="submit-button" disabled={submitDisabled}>
          Ajouter la mesure
        </button>
      </form>
    </div>)}
  </Fragment>
  );
};

export default AddMeasureForm;
