import React from "react";
import { useRef, useEffect, useState, useContext } from "react";
import * as mapboxgl from "mapbox-gl";
import ReactMapGL from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Marker, Source, Layer, MapContext, useMap } from "react-map-gl";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

const MapPresentation = ({ gameId, bassin, language }) => {
  console.log(language)

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
      console.log(t("welcome"))
    }
  }, [language, i18n]);

  const bassinList = ['rhone', 'adour', 'loire', 'rhin', 'seine', 'artois'];
  const bassinCentrer = {
    'rhone': { 'lat': 45.25, 'long': 4.75 },
    'adour': { 'lat': 43.70, 'long': -0.00 },
    'loire': { 'lat': 47.40, 'long': -1.00 },
    'rhin': { 'lat': 51.50, 'long': 6.75 },
    'seine': { 'lat': 48.50, 'long': 3.30 },
    'artois': { 'lat': 49.75, 'long': 2.50 }}

  const bassinToUse = bassinList.includes(bassin) ? bassin : 'rhone';

  mapboxgl.accessToken =
    "pk.eyJ1IjoibWFkdW1hcnRpbiIsImEiOiJjbGhseGtodnMwcmNyM2VvZG03aWppbzFwIn0.XIg46AX3zfY9AcQ8WEXsoQ";

  const navigate = useNavigate();
  const textArray = [
    // "Bienvenue dans l'Atelier Compte-Gouttes",
    t("welcome"),
    "Le territoire national est divisé en plusieurs bassins versants, qui correspondent aux territoires drainés par les grands fleuves français. Lors de cet atelier, nous nous placerons dans le bassin versant Rhône-Méditerranée décrit ci-dessous. Explorons ensemble ses différentes caractéristiques.",
    "Le principal fleuve de la zone est bien sur le Rhône, mais on compte au total près de 11 000 cours d'eau dans cette zone et un peu plus de 1000km de côtes.",
    "Ce territoire comprend près de 14 millions d'habitants dont l'approvisionnement en eau dépend grandement de ces cours d'eau. On retrouve ainsi des villes comme Lyon et Marseille.",
    "Le long du Rhône, on compte 4 centrales nucléaires qui fournissent une grande partie de l'électricité de la région et qui sont dépendantes de ce fleuve pour leur refroidissement.",
  ];

  const [index, setIndex] = useState(0);
  const [textDescription, setTextDescription] = useState({
    text: textArray[0],
    class: "text-intro-title",
  });
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [lineData, setLineData] = useState(null);
  const [pointData, setPointData] = useState(null);
  const [centraleData, setCentraleData] = useState(null);
  const [viewport, setViewport] = useState({
    width: "100%",
    height: "400px",
    latitude: bassinCentrer[bassinToUse]['lat'],
    longitude: bassinCentrer[bassinToUse]['long'],
    zoom: 5,
  });

  const handleNextClick = () => {
    // Charger les données de la ligne depuis le fichier GeoJSON
    if (index == 0) {
      console.log(window.location.origin + "/geodata/" + bassinToUse + "/bassin.geojson");
      fetch(window.location.origin + "/geodata/" + bassinToUse + "/bassin.geojson")
        .then((response) => response.json())
        .then((data) => setGeoJsonData(data))
        .catch((error) => console.error("Error fetching geojson data:", error));
    } else if (index == 1) {
      fetch(window.location.origin + "/geodata/" + bassinToUse + "/fleuve.geojson")
        .then((response) => response.json())
        .then((data) => setLineData(data))
        .catch((error) => console.error("Error fetching line data:", error));
      setViewport({
        width: "100%",
        height: "400px",
        latitude: bassinCentrer[bassinToUse]['lat'],
        longitude: bassinCentrer[bassinToUse]['long'],
        zoom: 6,
      });
    } else if (index == 2) {
      fetch(window.location.origin + "/geodata/" + bassinToUse + "/city.geojson")
        .then((response) => response.json())
        .then((data) => setPointData(data))
        .catch((error) => console.error("Error fetching point data:", error));
    } else if (index == 3) {
      fetch(window.location.origin + "/geodata/" + bassinToUse + "/centrales.geojson")
        .then((response) => response.json())
        .then((data) => setCentraleData(data))
        .catch((error) =>
          console.error("Error fetching centrale data:", error)
        );
    } else if (index == textArray.length - 1) {
      navigate("/tuto/" + gameId);
    }

    fetch(window.location.origin + "/geodata/" + bassinToUse + "/text_data.json")
    .then((response) => response.json())
    .then((data) => setTextDescription({
              text: data.text[index + 1],
              class: "text-intro-container",
            }))
    .catch((error) => console.error("Error fetching geojson data:", error));


    setIndex(index + 1);
    console.log(index);
  };

  return (
    <div>
      <div class={textDescription.class}>{textDescription.text}</div>
      <div className="map-container">
        <ReactMapGL
          {...viewport}
          //onViewportChange={(newViewport) => setViewport(newViewport)}
          onMove={(evt) => setViewport(evt.viewport)}
          //mapboxApiAccessToken={mapboxToken}
          mapStyle="mapbox://styles/mapbox/satellite-v9"
        >
          {geoJsonData && (
            <Source id="my-data" type="geojson" data={geoJsonData}>
              <Layer
                id="my-layer"
                type="fill"
                paint={{
                  "fill-color": "#0080ff",
                  "fill-opacity": 0.6,
                }}
              />
            </Source>
          )}

          {lineData && (
            <Source id="line-data" type="geojson" data={lineData}>
              <Layer
                id="line-layer"
                type="line"
                paint={{
                  "line-color": "blue",
                  "line-width": 2,
                }}
              />
            </Source>
          )}
          {pointData && (
            <Source id="points-data" type="geojson" data={pointData}>
              <Layer
                id="points-layer"
                type="circle"
                paint={{
                  "circle-color": "#FF0000",
                  "circle-radius": ["get", "size"],
                }}
              />
              <Layer
                id="text-layer"
                type="symbol"
                layout={{
                  "text-field": ["get", "name"],
                  "text-anchor": "top",
                  "text-offset": [0, 1],
                  "text-size": 15,
                  "text-font": ["Open Sans Bold"],
                }}
                paint={{
                  "text-color": "#FFFFFF",
                  "text-opacity": 1,
                  "text-halo-color": "#000000",
                  "text-halo-width": 1,
                }}
              />
            </Source>
          )}
          {/* {centraleData && (
                    <Source id="centrale-data" type="geojson" data={centraleData}>
                    <Layer
                        id="centrale-layer"
                        type="symbol"
                        layout={{
                            'icon-image': 'centrale', // Replace 'custom-icon' with the name of your icon image
                            'icon-size': 1.5,
                        }}
                    />
                    </Source>
                )} */}

          {centraleData &&
            centraleData.features.map((point) => (
              <Marker
                key={point.properties.id}
                latitude={point.geometry.coordinates[1]}
                longitude={point.geometry.coordinates[0]}
              >
                <img
                  src="/Icone_Centrale_Nucleaire.svg"
                  alt="Custom Icon"
                  style={{ width: "30px", height: "30px" }}
                />
                ;
                {/* <span className="marker-name">{point.properties.name}</span> */}
              </Marker>
            ))}
        </ReactMapGL>
        <div className="next-intro-section">
          <button class="blue-button" onClick={handleNextClick}>
            {t("Suivant")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MapPresentation;
