import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from "react-i18next";

const CustomIntroComponent = ({ gameId, configId, language }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  const [imageUrls, setImageUrls] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(true); // Ajout de l'état de chargement
  const navigate = useNavigate();

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/game_intro/${configId}/image_list`;
    
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        if (data.intro_img_list.length > 0) {
          setImageUrls(data.intro_img_list);
        } else {
          console.error('La réponse API est vide.');
        }
      })
      .catch(error => {
        console.error('Erreur lors du chargement des images:', error);
      })
      .finally(() => {
        setLoading(false); // Désactiver l'état de chargement
      });
  }, [gameId, configId]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => Math.min(prevIndex + 1, imageUrls.length - 1));
  };

  const previousImage = () => {
    setCurrentImageIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleStart = () => {
    navigate("/tuto/" + gameId);
  };

  return (
    <div className="game-intro-container">
      {loading ? (
        <div className="loader-container">
          <img src="/loader.gif" alt="Chargement..." className="loader" />
        </div>
      ) : imageUrls.length > 0 ? (
        <>
          <img 
            src={imageUrls[currentImageIndex]} 
            alt="Introduction" 
            className="game-intro-image" 
          />
          <div className="game-intro-controls">
            {currentImageIndex > 0 && (
              <button onClick={previousImage} className="blue-button">
                {t("Précédent")}
              </button>
            )}
            {currentImageIndex < imageUrls.length - 1 ? (
              <button onClick={nextImage} className="blue-button">
                {t("Suivant")}
              </button>
            ) : (<div></div>)}
          </div>
          {currentImageIndex >= imageUrls.length - 1 ? (
            <div className='game-intro-end-container'>
                <button onClick={handleStart} className="orange-button">
                    {t("Commencer l'atelier")}
                </button>
            </div>
            ) : (<div></div>)}
          
        </>
      ) : (
        <div className='game-intro-end-container'>
            <button onClick={handleStart} className="orange-button">
            {t("Commencer l'atelier")}
            </button>
        </div>
      )}
    </div>
  );
};

export default CustomIntroComponent;
