import React, { useState, useEffect, Fragment } from "react";
import { v4 as uuidv4 } from "uuid";


import { useNavigate } from "react-router-dom";
import useToken from "./useToken";


import TagSelector from "./GameFormComponents/TagSelector";
import "../form.css";

const ConsequenceForm = ({edit, consequence_id}) => {
  const [consequenceData, setConsequenceData] = useState({
    _id: uuidv4(),
    title: "",
    description: "",
    media: "",
    media_url: "",
    type: "picture", // par défaut "picture"
    owner: "",
    detail: "",
    tags:[],
    language: 'fr'
  });

  const existingTags = ["rhone", "adour", "loire", "paris"];

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  // security check
  const { token } = useToken();

  const fetchProfileInfos = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/profile`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 422) {
          navigate("/login"); // Redirection si non autorisé
        } else {
          console.error("Erreur lors du chargement des données de profil :", response.statusText);
        }
        return;
      }
      const profileData = await response.json();
      // Vérification des droits d'accès
      if (!profileData.is_admin) {
        navigate("/401"); // Redirection si l'utilisateur n'est pas admin
      }
    } catch (error) {
      console.error("Erreur lors du chargement des données de profil :", error);
    }
  };

  useEffect(() => {
    fetchProfileInfos();
  }, []); 


  // Charger les de la mesure si on est en mode edit
  useEffect(() => {
    const fetchConsequence = async () => {
      setLoading(true)
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/get_consequence_by_id/${consequence_id}`);
        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des conséquences");
        }
        const data = await response.json();
        setConsequenceData(data); // Charger les conséquences dans l'état
        setSelectedImage(data.media_url);
      } catch (error) {
        console.error("Erreur lors du chargement des conséquences:", error);
      }
      setLoading(false);
    };
    if (edit) {
      fetchConsequence();
    }
    
  }, []);

  // Gérer le changement de chaque champ
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setConsequenceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  //image upload
  const [loading, setLoading] = useState(false); // État de chargement
  const [selectedImage, setSelectedImage] = useState(null); // Image sélectionnée
  const [loadingImage, setLoadingImage] = useState(false); // État de chargement
  const [dragActive, setDragActive] = useState(false); // État de drag and drop

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Empêche la soumission du formulaire
    }
  };

  // Fonction pour télécharger l'image et obtenir l'URL de stockage
  const uploadImage = async (image) => {
    setLoadingImage(true);
    const formDataWithImage = new FormData();
    formDataWithImage.append("image", image);
    try {

      const response = await fetch(`${process.env.REACT_APP_API_URL}/upload_image`, {
        method: "POST",
        body: formDataWithImage,
      });
  
      // Vérifier si la requête a réussi
      if (!response.ok) {
        throw new Error("Erreur lors du téléchargement de l'image.");
      }
  
      // Récupérer l'URL de l'image depuis la réponse de l'API
      const data = await response.json();
  
      // Mettre à jour l'URL de l'image dans le formData
      setConsequenceData((prevData) => ({
        ...prevData,
        media_url: data.image_url, // Assurez-vous que l'API renvoie un objet avec une propriété `url`
      }));
    } catch (error) {
      console.error("Erreur lors du téléchargement de l'image", error);
    } finally {
      setLoadingImage(false);
    }
  };

  // Gestion du changement d'image via input
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      uploadImage(file); // Télécharger l'image dès qu'elle est sélectionnée
    }
  };

  // Gestion du drag and drop
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedImage(file);
      uploadImage(file); // Télécharger l'image déposée
    }
  };

  // Suppression de l'image sélectionnée
  const handleRemoveImage = () => {
    setSelectedImage(null);
    setConsequenceData((prevData) => ({
      ...prevData,
      media_url: "",
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    // Bloquer le bouton Submit pendant la requête
    setSubmitDisabled(true);
    
    const url = edit
      ? `${process.env.REACT_APP_API_URL}/replace_consequence/${consequence_id}`
      : `${process.env.REACT_APP_API_URL}/add_consequence`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(consequenceData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // En cas de succès, afficher le message et désactiver le bouton submit
          setMessage("La mesure a été ajoutée avec succès.");
        } else {
          // En cas d'échec, afficher l'erreur et réactiver le bouton submit
          setMessage(`${data.message}`);
          setSubmitDisabled(false); // Réactiver le bouton en cas d'échec
        }
      })
      .catch((error) => {
        setMessage(`Erreur: ${error.message}`);
        setSubmitDisabled(false); // Réactiver le bouton en cas d'erreur de requête
      });
  };

  const handleBackToConsequences = () => {
    navigate("/consequence_list"); // Utiliser `navigate` pour rediriger
  };


  return (
    <Fragment>
    {loading ? (
      <div className="loader-container">
        <img src="/loader.gif" className="loader" />
      </div>
    ) : (
    <form onSubmit={handleSubmit} className="consequence-form">
      <h1 className="form-title">Ajouter une nouvelle conséquence</h1>
      <div className="form-section">
        <div className="form-section-title">Informations générales</div>
        <div className="form-group">
          {!edit ? (<div className="form-group">
            <label>ID :</label>
            <input
            type="text"
            name="_id"
            value={consequenceData._id}
            onChange={handleInputChange}
            className="input"
            placeholder="Identifiant unique"
            onKeyDown={handleKeyDown}
            required
            />
          </div>):(<div><div>ID : {consequenceData._id}</div><br></br></div>)}
        </div>

        <div className="form-group">
            <label>Titre :</label>
            <input
            type="text"
            name="title"
            value={consequenceData.title}
            onChange={handleInputChange}
            className="input"
            placeholder="Titre de la conséquence"
            onKeyDown={handleKeyDown}
            required
            />
        </div>

        <div className="form-group">
            <label>Description :</label>
            <textarea
            name="description"
            value={consequenceData.description}
            onChange={handleInputChange}
            className="input"
            placeholder="Description complète"
            onKeyDown={handleKeyDown}
            required
            />
        </div>
      </div>

      <div className="form-section">
        <div className="form-section-title">Média</div>
        <div className="form-group">
            <label>Type de conséquence :</label>
            <select
            name="media"
            value={consequenceData.media}
            onChange={handleInputChange}
            className="input"
            required
            >
            <option value="newspaper">Journal</option>
            <option value="video">Vidéo</option>
            <option value="linkedin">LinkedIn</option>
            <option value="tweet">Tweet</option>
            </select>
        </div>

        <div className="form-group">
            <label>Type de média :</label>
            <select
            name="type"
            value={consequenceData.type}
            onChange={handleInputChange}
            className="input"
            >
            <option value="picture">Photo</option>
            <option value="video">Vidéo</option>
            </select>
        </div>

        {/* Zone de dépôt d'image avec drag and drop */}
        <div
            className={`dropzone ${dragActive ? "active" : ""}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <label className="input-label">Téléchargez une image :</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="input-file"
              style={{ display: "none" }} // Masquer l'input file
              id="image-upload"
            />
            <label htmlFor="image-upload" className="dropzone-label">
              {selectedImage
                ? `Image sélectionnée : `
                : "Glissez et déposez une image ou cliquez pour en sélectionner une"}
            </label>
            {selectedImage ?
            (<div>
              <a href={consequenceData.media_url} target="_blank">Lien de l'image</a>
            </div>):(<></>)}

            {/* Indicateur de chargement */}
            {loadingImage && <div className="loading-indicator">Téléchargement en cours...</div>}

            {/* Afficher le bouton pour supprimer l'image si elle est sélectionnée */}
            {selectedImage && !loadingImage && (
              <button type="button" onClick={handleRemoveImage} className="remove-button">
                Supprimer l'image
              </button>
            )}
        </div>
      </div>

      <div className="form-section">
        <div className="form-section-title">Metadata</div>

        <div className="form-group">
            <label>Langage :</label>
            <select
            name="language"
            value={consequenceData.language}
            onChange={handleInputChange}
            className="input"
            >
            <option value="fr">fr</option>
            <option value="en">en</option>
            </select>
        </div>

        <TagSelector formData={consequenceData} setFormData={setConsequenceData} existingTags={existingTags} />


        <div className="form-group">
            <label>Propriétaire :</label>
            <input
            type="text"
            name="owner"
            value={consequenceData.owner}
            onChange={handleInputChange}
            className="input"
            placeholder="Propriétaire de la conséquence"
            onKeyDown={handleKeyDown}
            />
        </div>

        <div className="form-group">
            <label>Détails :</label>
            <textarea
            name="detail"
            value={consequenceData.detail}
            onChange={handleInputChange}
            className="input"
            placeholder="Détails supplémentaires"
            onKeyDown={handleKeyDown}
            />
        </div>
    </div>

      {/* Afficher le message de succès ou d'erreur */}
      {message && <div className="message-box">{message}</div>}

      {/* Bouton "Retour aux conséquences" affiché après la soumission */}
      {message && (
        <button type="button" onClick={handleBackToConsequences} className="submit-button">
          Retour aux conséquences
        </button>
      )}

      {/* Le bouton submit */}
      <button type="submit" className="submit-button" disabled={submitDisabled}>
        Envoyer la conséquence
      </button>

    </form>)}
    </Fragment>
  );
};

export default ConsequenceForm;
